.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 0.5rem 1.5rem;
    height: 6rem;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1.5rem;

    color: white;

    /* backdrop-filter: blur(8px); */
    /* background: hsl(128 100% 0% / 0.7);
    background: linear-gradient(180deg, hsl(128 100% 1% / 0.5) 20%, hsl(128 100% 1% / 0.2)); */
    /* mask: linear-gradient(black, black 0%, transparent); */

    z-index: 1;
}

.logo {
    margin: 0.5rem -1rem 0;
    width: 220px;
    height: 70px;
    object-fit: cover;
    position: relative;
}

.container {
    width: 100%;
    max-width: 1920px;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.media {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.mediaIcon {}

@media (max-height: 940px) and (min-width: 960px) {
    .video {
        padding-top: 0;
        height: 100%;
        width: 134vh;
    }
}