/* cyrillic-ext */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_36bd41';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_36bd41 {font-family: '__Inter_36bd41', '__Inter_Fallback_36bd41';font-style: normal
}

:root {
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

body {
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}


iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  /* height: calc(100% - 80px);
  top: 40px; */
}

.Header_header__AF_3G {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 0.5rem 1.5rem;
    height: 6rem;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1.5rem;

    color: white;

    /* backdrop-filter: blur(8px); */
    /* background: hsl(128 100% 0% / 0.7);
    background: linear-gradient(180deg, hsl(128 100% 1% / 0.5) 20%, hsl(128 100% 1% / 0.2)); */
    /* mask: linear-gradient(black, black 0%, transparent); */

    z-index: 1;
}

.Header_logo__1FRrW {
    margin: 0.5rem -1rem 0;
    width: 220px;
    height: 70px;
    object-fit: cover;
    position: relative;
}

.Header_container__3JNoS {
    width: 100%;
    max-width: 1920px;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.Header_media__5_Ace {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.Header_mediaIcon__nj3zU {}

@media (max-height: 940px) and (min-width: 960px) {
    .Header_video__grC_O {
        padding-top: 0;
        height: 100%;
        width: 134vh;
    }
}
