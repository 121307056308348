:root {
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

body {
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}


iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  /* height: calc(100% - 80px);
  top: 40px; */
}
